"use client"
import { ReactNode, useEffect } from "react"

const InViewObserver = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains("home-hide-up")) {
              entry.target.classList.add("home-show-up")
            }

            if (entry.target.classList.contains("home-hide-down")) {
              entry.target.classList.add("home-show-down")
            }
            observer.unobserve(entry.target)
          } else {
            if (entry.target.classList.contains("home-hide-up"))
              entry.target.classList.remove("home-show-up")

            if (entry.target.classList.contains("home-hide-down"))
              entry.target.classList.remove("home-show-down")
          }
        })
      },
      { threshold: 0.6 }
    )

    const elements = document.querySelectorAll(".home-hide-up, .home-hide-down")
    elements.forEach((el) => observer.observe(el))

    return () => observer.disconnect()
  }, [])

  return <>{children}</>
}

export default InViewObserver
