"use client"
import { useState } from "react"
import { usePathname } from "next/navigation"
import Image from "next/image"
import Link from "next/link"
import { Session } from "next-auth"
import NavMenu from "./NavMenu"
import { Logo } from "./svg/logo"
import { ClientSafeProvider } from "next-auth/react"
import { SignInButton } from "./Button"
import { IconDiscountCheckFilled } from "@tabler/icons-react"

type NavMenuButtonProps = {
  session: Session | null
  openMenu: () => void
}

export const NavMenuButton = ({ session, openMenu }: NavMenuButtonProps) => {
  if (!session) return null
  let currentPlan = session?.user?.current_plan
  if (currentPlan) {
    currentPlan = currentPlan.split(" ")[0]
  }

  console.log(currentPlan)
  return (
    <div className="flex items-center justify-center">
      {session.user.role === "admin" && (
        <Link
          className="text-black hover:text-pink-500 transition mr-4 !p-2 !text-sm"
          href="/admin"
        >
          Admin
        </Link>
      )}
      <div className="relative">
        {currentPlan === "Pro" && (
          <div className="absolute bottom-3 left-1 translate-y-3 -translate-x-3 z-[400]">
            <IconDiscountCheckFilled className=" relative text-spring-green-600 z-[401]" />
            <div className="absolute top-2 left-2 bg-white w-2 h-2 z-[400]" />
          </div>
        )}
        <button
          aria-label="Open navigation menu relative"
          onClick={openMenu}
          className="border-2 border-black shadow-[2px_2px_0_0_#000] active:shadow-[0_0_0_0_#000] active:translate-y-[2px] active:translate-x-[2px]  transition h-12 w-12 rounded-full focus:outline-none"
        >
          {session?.user?.image && (
            <Image
              fill
              className="user-select-none rounded-full !relative"
              src={session?.user.image as string}
              alt="User"
            />
          )}
        </button>
      </div>
    </div>
  )
}

const Navbar = ({
  session,
  providers,
}: {
  session: Session | null
  providers: ClientSafeProvider
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const currentRoute = usePathname()
  const isHome = currentRoute === "/"

  const showMainNavRoutes = [
    "/settings",
    "/auth/signin",
    "/admin",
    "/analytics",
    "/",
    "/terms",
    "/privacy",
    "/support",
    "/dashboard",
    "/pricing",
    "/quotes",
    `/quotes/[quote_id]`,
    `/quotes/${currentRoute.split("/")[2]}`,
  ]

  const showMainNav = showMainNavRoutes.includes(currentRoute)

  return (
    <nav
      className={`${isHome ? "hidden" : ""} ${
        showMainNav ? "bg-[#ffffea] border-b-4 border-b-black" : "hidden"
      } fixed top-0 left-0 w-full flex items-center justify-center z-[100] `}
    >
      <div
        className={`w-full md:container flex ${
          showMainNav ? "justify-between" : "justify-end"
        } items-center p-2 relative`}
      >
        <Link
          href="/"
          className={showMainNav ? "text-2xl text-black font-bold" : "hidden"}
        >
          <Logo className="fill-black w-14 h-14" full />
        </Link>

        <NavMenuButton session={session} openMenu={handleMenuOpen} />
        {!session && (
          <SignInButton
            // @ts-ignore
            providers={providers}
            callback={currentRoute}
            isGreen
            isNav
            buttonText={"Sign In"}
          />
        )}
      </div>
      {session && (
        <NavMenu
          session={session}
          close={() => setIsMenuOpen(false)}
          isOpen={isMenuOpen}
        />
      )}
    </nav>
  )
}

export default Navbar
