"use client"
import { useState } from "react"
import NavMenu from "@/components/NavMenu"
import { NavMenuButton } from "@/components/Navbar"
import { Session } from "next-auth"

const LandingPageNav = ({ session }: { session: Session | null }) => {
  const [navMenuOpen, setNavMenuOpen] = useState(false)

  return (
    <>
      <NavMenuButton
        session={session || null}
        openMenu={() => setNavMenuOpen(!navMenuOpen)}
      />
      <NavMenu
        session={session}
        isOpen={navMenuOpen}
        close={() => setNavMenuOpen(false)}
      />
    </>
  )
}

export default LandingPageNav
