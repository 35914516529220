import Image from "next/image"

type LogoProps = {
  className: string
  containerClassName?: string
  textClassName?: string
  full?: boolean
}

export const Logo = ({
  className,
  containerClassName,
  textClassName,
  full,
}: LogoProps) => (
  <div
    className={`w-full flex justify-start items-center px-0 py-2 relative user-select-none select-none ${
      containerClassName || ""
    }`}
  >
    <Image src="/lcqp-logo-small.png" width={175} height={48} alt="logo" />
    {/* <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="618.000000pt"
      height="618.000000pt"
      viewBox="0 0 618.000000 618.000000"
      preserveAspectRatio="xMidYMid meet"
      className={`fill-black ${className}`}
      fill="currentColor"
    >
      <g
        transform="translate(0.000000,618.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M638 5638 c-310 -39 -452 -312 -309 -595 16 -32 37 -68 47 -81 l19
-23 83 47 c45 26 82 50 82 54 0 4 -16 41 -35 83 -61 132 -45 225 47 277 42 25
47 25 268 25 210 0 228 -1 272 -22 87 -40 136 -98 244 -288 54 -96 102 -175
105 -175 3 0 45 23 92 51 l87 52 -42 76 c-91 165 -157 276 -188 315 -41 50
-121 117 -175 144 -100 50 -132 56 -341 61 -111 4 -226 3 -256 -1z"
        />
        <path
          d="M1631 4854 c-41 -23 -78 -47 -84 -53 -6 -6 40 -100 138 -278 81 -147
206 -374 278 -503 71 -129 153 -278 182 -330 29 -52 80 -144 113 -205 119
-222 295 -542 333 -606 36 -62 46 -71 151 -136 62 -39 147 -90 188 -113 41
-24 86 -50 100 -59 15 -10 10 -1 -15 25 -22 22 -59 73 -83 113 -60 102 -296
525 -442 791 -160 293 -310 565 -485 880 -77 140 -174 315 -214 388 -40 72
-76 132 -80 131 -3 -1 -39 -21 -80 -45z"
        />
        <path
          d="M548 4842 c-43 -25 -78 -50 -78 -54 0 -5 22 -47 49 -96 27 -48 124
-224 217 -392 92 -168 259 -469 371 -670 l203 -365 -230 -3 -231 -2 3 -138 3
-137 90 -46 90 -46 5 -584 c5 -530 7 -588 23 -629 18 -45 65 -130 72 -130 2 0
15 25 29 56 105 229 304 388 549 441 98 21 267 13 362 -17 253 -80 433 -253
522 -501 21 -60 27 -97 31 -196 4 -107 -6 -221 -24 -268 -5 -13 45 -15 429
-15 l434 0 5 138 c6 162 33 260 98 361 205 317 600 429 936 265 227 -111 367
-329 376 -581 2 -46 6 -83 10 -83 4 0 19 5 35 11 15 6 100 36 188 66 88 30
169 63 180 74 32 31 27 153 -14 314 -66 266 -178 419 -375 515 -125 61 -112
60 -895 60 l-714 0 -81 46 c-45 26 -202 119 -351 207 -455 270 -525 311 -680
402 -82 48 -248 146 -368 217 l-217 130 -68 117 c-85 143 -393 695 -657 1176
-232 422 -222 405 -238 404 -6 0 -47 -21 -89 -47z"
        />
        <path
          d="M3541 3564 c-47 -24 -111 -90 -111 -116 0 -4 261 -8 580 -8 319 0
580 4 580 9 0 19 -83 101 -123 120 -42 20 -58 21 -458 21 l-415 0 -53 -26z"
        />
        <path
          d="M3205 3331 c-46 -21 -79 -54 -94 -94 -6 -16 -11 -106 -11 -213 l0
-184 915 0 915 0 0 163 c0 89 -5 180 -11 202 -15 56 -54 104 -103 126 -39 18
-80 19 -806 19 -723 0 -767 -1 -805 -19z"
        />
        <path
          d="M3320 2506 l0 -216 375 0 375 0 0 80 0 79 -59 41 c-80 53 -82 60 -22
60 79 0 81 2 81 85 0 61 -3 73 -17 77 -10 3 -179 6 -375 7 l-358 3 0 -216z"
        />
        <path d="M4200 2635 l0 -75 280 0 280 0 0 75 0 75 -280 0 -280 0 0 -75z" />
        <path d="M4200 2370 l0 -80 280 0 280 0 0 80 0 80 -280 0 -280 0 0 -80z" />
        <path
          d="M1667 1854 c-102 -24 -185 -72 -268 -154 -138 -137 -185 -262 -177
-474 6 -142 29 -214 104 -327 59 -89 129 -150 229 -200 241 -119 509 -75 700
116 125 124 180 260 179 440 -1 93 -5 122 -28 190 -68 199 -236 357 -436 410
-69 18 -226 18 -303 -1z m218 -423 c88 -40 137 -133 115 -220 -39 -157 -228
-208 -334 -91 -51 57 -67 124 -45 191 38 113 159 168 264 120z"
        />
        <path
          d="M5340 1860 c0 -6 7 -25 16 -43 27 -51 71 -190 90 -279 23 -113 15
-209 -24 -292 -43 -91 -107 -134 -269 -181 -48 -14 -92 -27 -97 -28 -16 -5
126 -93 179 -111 28 -10 84 -20 125 -23 239 -18 450 152 492 395 40 234 -120
482 -352 545 -79 22 -160 30 -160 17z"
        />
        <path
          d="M4070 1713 c-64 -10 -121 -29 -192 -65 -134 -69 -238 -204 -274 -354
-21 -88 -15 -236 13 -315 137 -400 626 -520 923 -227 273 268 198 735 -144
903 -104 52 -228 74 -326 58z m143 -397 c44 -18 95 -78 103 -121 27 -146 -116
-267 -249 -211 -73 30 -125 125 -108 197 27 119 144 181 254 135z"
        />
      </g>
    </svg>

    <p
      className={` flex-col items-start justify-end font-tilt_warp text-2xl text-black font-bold hidden ml-1 select-none translate-y-1 ${
        full ? "md:flex" : "hidden"
      } ${textClassName || ""}`}
    >
      <span className="block">Lawn Care</span>
      <span className="block -translate-y-2">
        Quote <span className="text-spring-green-600">Pro</span>
      </span>
    </p> */}
  </div>
)
