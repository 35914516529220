import { ReactNode, useEffect, useRef } from "react"
import Link from "next/link"
import { signOut } from "next-auth/react"
import { useClickOutside } from "@/hooks/useClickOutside"
import { Session } from "next-auth"
import { bezier } from "@/constants"
import { IconX } from "@tabler/icons-react"

type NavMenuProps = {
  close: () => void
  isOpen: boolean
  session: Session | null
}

type NavMenuItemProps = {
  href: string
  children: ReactNode
  closeMenu: () => void
}

const NavMenuItem = ({ href, children, closeMenu }: NavMenuItemProps) => (
  <li className="border-b-2 border-zinc-800">
    <Link
      href={href}
      onClick={closeMenu}
      className="py-4 hover:text-spring-green-600 w-full transition block"
    >
      {children}
    </Link>
  </li>
)

const NavMenu = ({ close, isOpen, session }: NavMenuProps) => {
  const navMenuRef = useRef<HTMLDivElement>(null)
  useClickOutside(navMenuRef, close)

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto"
  }, [isOpen])

  return (
    <div
      ref={navMenuRef}
      className={`fixed top-0 ${
        isOpen ? "translate-x-0" : "translate-x-[100vw]"
      } right-0 transition duration-300 ${bezier} w-full sm:max-w-xl h-[100svh] overflow-auto flex flex-col items-center justify-center sm:pl-1 z-[2001]`}
    >
      <div className="bg-white sm:border-l-4 sm:border-black w-screen sm:w-full h-full shadow-md container flex flex-col items-center justify-center relative">
        <button
          aria-label="Close"
          onClick={close}
          className="absolute top-4 right-4 focus:outline-none text-black hover:text-spring-green-600 transition"
        >
          <IconX />
        </button>
        <ul className="text-xl text-black text-center font-bold child:border-b-2 child:border-zinc-800">
          <NavMenuItem closeMenu={close} href="/dashboard">
            Dashboard
          </NavMenuItem>
          <NavMenuItem closeMenu={close} href="/analytics">
            Analytics
          </NavMenuItem>
          {session?.user.username && (
            <NavMenuItem closeMenu={close} href={`/${session?.user.username}`}>
              View Page
            </NavMenuItem>
          )}
          <NavMenuItem closeMenu={close} href="/settings">
            Settings
          </NavMenuItem>
          <li>
            <button
              aria-label="Sign out"
              onClick={() => signOut()}
              className="py-4 hover:text-spring-green-600 w-full transition"
            >
              Sign Out
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default NavMenu
