"use client"
import { randomNumberBetween } from "@/utils/formatNumber"
import { set } from "mongoose"
import { useEffect, useState } from "react"
import CountUp from "react-countup"

export const QuotePriceBox = ({
  price,
  isDisplay,
}: {
  price: number
  isDisplay: boolean
}) => {
  const [quote, setQuote] = useState(0)
  useEffect(() => {
    setQuote(price)
    if (isDisplay) {
      const interval = setInterval(() => {
        setQuote(randomNumberBetween(85, 250))
      }, 5000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [])
  return (
    <div className="flex flex-col items-center justify-center z-[90]">
      <p className="mb-2 text-center text-zinc-700">
        Your quote is estimated to be
      </p>
      <p className="flex items-center justify-center text-pink-600 min-w-[220px] min-h-[120px] text-7xl font-bold mb-4 text-center bg-white p-4 rounded-2xl border-4 border-black shadow-[4px_4px_0_0_#000]">
        {/* ${quote} */}
        <CountUp prefix="$" end={quote} />
      </p>
      {!isDisplay && (
        <p className="text-center text-sm text-zinc-600 mb-4">per month</p>
      )}
    </div>
  )
}
